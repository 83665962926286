<template lang="pug">
v-main(v-if='Servicios' fluid)
    //-PC
    div.d-none.d-md-flex
      v-img( gradient="to top right, rgba(180, 50, 36, 0.45) , transparent" :src='Servicios.Arquitectura.portada' aspect-ratio='3' )
        v-row.mt-12
        v-row.mt-12    
        v-row.mt-12    
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12 
        v-row.mt-12.ml-12.mb-12 
          div.rectangulo_titulo.secundario.mr-4.mt-4
          h1(v-html='Servicios.Arquitectura.título').white--text 
        //- v-divider.primario.divider_inicial
    //-Móvil
    div.d-md-none
      v-img( gradient="to top right, rgba(0, 0, 0,.8) ,rgba(69, 64, 64 ,.28)" :src='Servicios.Arquitectura.portada' aspect-ratio=1.9)
        v-row.align-center.justify-center        
          v-col(md=11)
            v-row.mt-12 
            v-row.mt-12 
            v-row.mt-12 
            v-row.align-start.justify-start.mt-5.ml-4 
                div.rectangulo_titulo_movil.primario.mt-4.mr-2
                h2.white--text.mt-4 ARQUITECTURA
    Que_es_container
    Importante_container
    v-divider.primario.divider_inicial
    Beneficios_container
    v-divider.primario.divider_inicial
    Servicios_de_construccion
</template>
<script>
export default {
    components: {
        Que_es_container: ()=>import('./componentes/qué_es_container'),
        Importante_container: ()=>import('./componentes/importante_container'),
        Beneficios_container: ()=>import('./componentes/beneficios_container'),
        Servicios_de_construccion: ()=>import('./componentes/servicios_de_construcción'),
    },
}
</script>
<style lang="sass">
  .rectangulo_titulo
    width: 14px
    height: 73px
  .rectangulo_titulo_movil
    width: 10px
    height: 40px
</style>
<style lang="sass">
  .divider_inicial
    max-height: 10px!important
    height: 9px!important
</style>